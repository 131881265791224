.react-resizable {
    position: relative;
    background-clip: padding-box;
    user-select: none;

    &-handle {
        position: absolute;
        right: -5px;
        bottom: 0;
        z-index: 1;
        width: 10px;
        height: 100%;
        cursor: col-resize;
        user-select: none;
    }
}

.editable-cell {
    position: relative;

    &-value-wrap {
        padding: 5px 12px;
        cursor: copy;
    }
}

.editable-row:hover {
    .editable-cell-value-wrap {
        padding: 4px 11px;
        border: 1px solid #c8f8e6;
        border-radius: 2px;
    }
}
