body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f5f5f5;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    white-space: break-spaces;
}

.ant-layout,
.ant-layout * {
    font-family: 'Inter', sans-serif;
}

button {
    background: transparent;
    border: none;
}

.title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #626262;
    margin-bottom: 20px;
    text-align: center;
}

.custom-steps.ant-steps-vertical {
    flex-direction: row !important;
    width: 50%;
    margin: 0 auto;
}

.custom-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    display: none;
}

input::placeholder {
    color: #9f9f9f;
}
.dropdown-overlay {
    max-height: 200px;
    overflow-y: auto;
}